requirejs.config({
    baseUrl: '/build/base/js/',
    paths: {
        jquery: 'lib/jquery.min',

        // COMPONENTS
        aos: 'components/aos',
        stickyjs: 'components/sticky',
        interview: 'components/interview',
        jquerymodal: 'components/jquerymodal',
        lazysizes: 'components/lazy',
        navigation: 'components/navigation',
        photoswipe: 'components/photoswipe',
        slick: 'components/slick',
        macksperience: 'components/macksperience',

        // LIBRARIES
        lib_aos: 'lib/aos',
        lib_stickyjs: 'lib/sticky',
        lib_slick: 'lib/slick',
        lib_lazysizes: 'lib/lazysizes/slick',
        lib_jquerymodal: 'lib/jquery.modal.min',
        lib_photoswipe: 'lib/photoswipe/photoswipe',
        lib_photoswipe_ui: 'lib/photoswipe/photoswipe-ui-default'
    },
    shim: {
        jquerymodal: ['jquery']
    }
});
